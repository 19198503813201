<template>
  <li class="menu-item">
    <span class="form-label mr-auto">
      <i class="mr-3" :class="'i-' + type"></i> {{ placeholder }}</span
    >

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="zip"
      :download="zip"
      :href="zip"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="sfb"
      :download="sfb"
      :href="sfb"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <span
      class="btn btn-sm bg-white md-down:p-0"
      @click="playAudio"
      v-if="audio"
    >
      <i class="i-play float-left md-down:d-none"></i>
      <i class="i-play lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("audio_play") }}</span>
    </span>

    <mega-button
      class="btn-sm bg-white md-down:p-0"
      @click="$refs['file'].click()"
    >
      <i class="i-upload float-left md-down:d-none"></i>
      <i class="i-upload lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_upl") }}</span>
    </mega-button>

    <input
      class="input-file"
      hidden
      type="file"
      ref="file"
      :accept="accept"
      @change="addFiles"
    />
  </li>
</template>

<script>
export default {
  name: "ModelNewPage",
  props: ["type", "placeholder", "modif", "accept"],
  data() {
    return {
      file: undefined
    };
  },
  computed: {
    zip() {
      return this.type === "apple" && this.modif["url_archive"];
    },
    sfb() {
      return this.type === "android" && this.modif["url_sfb"];
    },
    audio() {
      return this.type === "music" && this.modif["audio"];
    }
  },
  mounted() {
    this.eventListener(
      this.$el,
      "drag dragstart dragend dragover dragenter dragleave drop",
      e => {
        e.preventDefault();
        e.stopPropagation();
      }
    );

    this.eventListener(this.$el, "dragover dragenter", () => this.dragEnter());
    this.eventListener(this.$el, "dragleave dragend drop", () =>
      this.dragEnd()
    );
    this.eventListener(this.$el, "drop", e => this.dragDropped(e));
  },
  methods: {
    playAudio() {
      let audio = document.createElement("audio");
      audio.src = this.modif["audio"]["url"];
      audio.play();

      audio.onended = function() {
        audio.outerHTML = "";
      };
    },

    target() {
      this.$refs["file"].click();
    },

    addFiles() {
      let files = this.$refs["file"].files;

      if (files) this.file = files[0];

      this.$emit("input", this.file);
      this.$emit("drop");

      let formData = new FormData();
      formData.append("idt_model_modif", this.modif["idt_model_modif"]);

      if (this.type === "android") formData.append("sfb", this.file);

      if (this.type === "apple") formData.append("archive", this.file);

      if (this.type === "music") formData.append("audio", this.file);

      this.$api.v2
        .put("/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          this.$emit("drop");
          this.$refs["file"].value = "";
          this.file = undefined;
        });
    },

    addEvent(obj, type, fn) {
      if (obj.addEventListener) {
        obj.addEventListener(type, fn, false);
      } else if (obj.attachEvent) {
        obj.attachEvent("on" + type, function() {
          return fn.call(obj, window.event);
        });
      }
    },

    eventListener(el, events, listener) {
      events = events.split(" ");

      events.map(event => {
        this.addEvent(el, event, listener);
      });
    },

    dragEnter() {
      this.$el.classList.add("drag-hover");
    },

    dragEnd() {
      this.$el.classList.remove("drag-hover");
    },

    dragDropped(e) {
      if (e.dataTransfer.items) this.file = e.dataTransfer.items[0].getAsFile();

      this.$emit("input", this.file);
      this.$emit("drop");
    }
  }
};
</script>

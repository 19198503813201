var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "menu-item" },
    [
      _c("span", { staticClass: "form-label mr-auto" }, [
        _c("i", { staticClass: "mr-3", class: "i-" + _vm.type }),
        _vm._v(" " + _vm._s(_vm.placeholder))
      ]),
      _vm._v(" "),
      _vm.zip
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.zip, href: _vm.zip }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sfb
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.sfb, href: _vm.sfb }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.audio
        ? _c(
            "span",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              on: { click: _vm.playAudio }
            },
            [
              _c("i", { staticClass: "i-play float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-play lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("audio_play")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "mega-button",
        {
          staticClass: "btn-sm bg-white md-down:p-0",
          on: {
            click: function($event) {
              return _vm.$refs["file"].click()
            }
          }
        },
        [
          _c("i", { staticClass: "i-upload float-left md-down:d-none" }),
          _vm._v(" "),
          _c("i", { staticClass: "i-upload lg-up:d-none" }),
          _vm._v(" "),
          _c("span", { staticClass: "md-down:d-none" }, [
            _vm._v(_vm._s(_vm.$t("model_arch_upl")))
          ])
        ]
      ),
      _vm._v(" "),
      _c("input", {
        ref: "file",
        staticClass: "input-file",
        attrs: { hidden: "", type: "file", accept: _vm.accept },
        on: { change: _vm.addFiles }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }